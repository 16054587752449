// extracted by mini-css-extract-plugin
export var container = "trip-of-a-lifetime-section-module--container--V8l2j";
export var inner = "trip-of-a-lifetime-section-module--inner--jvIEs";
export var imageContainer = "trip-of-a-lifetime-section-module--image-container--6JDQP";
export var imageWrapper = "trip-of-a-lifetime-section-module--image-wrapper--HeX4A";
export var image = "trip-of-a-lifetime-section-module--image--4MIZm";
export var content = "trip-of-a-lifetime-section-module--content--TDyYJ";
export var contentInner = "trip-of-a-lifetime-section-module--content-inner--d+Qty";
export var titleWrapper = "trip-of-a-lifetime-section-module--title-wrapper--wLDMo";
export var contentText = "trip-of-a-lifetime-section-module--content-text--PVn91";
export var mobileImageWrapper = "trip-of-a-lifetime-section-module--mobile-image-wrapper--5PfZk";
export var mobileImage = "trip-of-a-lifetime-section-module--mobile-image--SCsUh";