import React from 'react';
import Layout from '../components/Layout';
import * as styles from './preflight.module.css';
import Container from '../components/Container';
import PageTitle from '../components/PageTitle';
import ReadyToBecomeAstronautSection from '../components/ReadyToBecomeAstronautSection';
import previewImg from './images/pre-flight-page-preview-section-bg.jpg';
import previewMobImg from './images/pre-flight-page-preview-section-bg-mob.jpg';
import PreviewSection from '../components/PreviewSection';
import TypographyBold from '../components/TypographyBold';
import TripOfALifetimeSection from '../components/TripOfALifetimeSection';
import WelcomeToSpaceportSection from '../components/WelcomeToSpaceportSection';
import CheckingShipshapeSection from '../components/CheckingShipshapeSection';
import PreviewSectionInner from '../components/PreviewSectionInner';

const PreFlightPage = () => {
  return (
    <Layout pageTitle='Virgin Galactic | Pre-Flight' description='Be fully prepared for the experience of a lifetime with our pre-flight activities and training.'>
      <PreviewSection image={previewImg} mobImage={previewMobImg} withFilter alt='Unity'>
        <Container size='sm'>
          <PreviewSectionInner>
            <div className={styles.pageTitleWrapper}>
              <PageTitle>
                We’ll make sure
                <br/>
                <TypographyBold> all systems are go. Including you.</TypographyBold>
              </PageTitle>
            </div>
          </PreviewSectionInner>
        </Container>
      </PreviewSection>
      <TripOfALifetimeSection />
      <WelcomeToSpaceportSection />
      <CheckingShipshapeSection />
      {/*<FaqSection />*/}
      <ReadyToBecomeAstronautSection />
    </Layout>
  );
};

export default PreFlightPage;
