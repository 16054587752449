// extracted by mini-css-extract-plugin
export var container = "checking-shipshape-section-module--container--7Xl+7";
export var inner = "checking-shipshape-section-module--inner--DXtB2";
export var imageContainer = "checking-shipshape-section-module--image-container--H-RLZ";
export var imageWrapper = "checking-shipshape-section-module--image-wrapper--fE+lT";
export var image = "checking-shipshape-section-module--image--aNFoF";
export var contentWrapper = "checking-shipshape-section-module--content-wrapper--mYgkB";
export var content = "checking-shipshape-section-module--content--dxt-+";
export var contentInner = "checking-shipshape-section-module--content-inner---puB8";
export var titleWrapper = "checking-shipshape-section-module--title-wrapper--6BRm+";
export var contentText = "checking-shipshape-section-module--content-text--40aFA";
export var mobileImageWrapper = "checking-shipshape-section-module--mobile-image-wrapper--8StGz";
export var mobileImage = "checking-shipshape-section-module--mobile-image--FFA1m";