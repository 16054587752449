import React from 'react';
import * as styles from './welcome-to-spaceport-section.module.css';
import Container from '../Container';
import SectionTitle from '../SectionTitle';
import image from './images/image.jpg';
import mobileImage from './images/image-mob.jpg';
import TypographyBold from '../TypographyBold';


const WelcomeToSpaceportSection = () => {
  return (
    <section className={styles.container}>
      <Container size='lg'>
        <div className={styles.inner}>
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <div className={styles.titleWrapper}>
                <SectionTitle>
                  Welcome to the world’s first
                  <TypographyBold> Spaceport America.</TypographyBold>
                </SectionTitle>
              </div>
              <div className={styles.mobileImageWrapper}>
                <img src={mobileImage} className={styles.mobileImage} alt='Spaceport' />
              </div>
              <div>
                <p className={styles.contentText}>
                  Ahead of your spaceflight, you will spend several days training with your fellow astronauts members and the Virgin Galactic team at the world’s first purpose-built commercial spaceport in New Mexico.
                </p>
                <p className={styles.contentText}>
                  Designed by renowned architectural firm Foster + Partners, Spaceport America incorporates a super hangar, crew zones and world-class astronaut areas for a truly unforgettable experience. This is your gateway to space.
                </p>
              </div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <div className={styles.imageWrapper}>
              <img src={image} className={styles.image} alt='Spaceport' />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default WelcomeToSpaceportSection;
