// extracted by mini-css-extract-plugin
export var container = "welcome-to-spaceport-section-module--container--wt+rR";
export var inner = "welcome-to-spaceport-section-module--inner--dyF9x";
export var imageContainer = "welcome-to-spaceport-section-module--image-container--Vf+YN";
export var imageWrapper = "welcome-to-spaceport-section-module--image-wrapper--0K4PM";
export var image = "welcome-to-spaceport-section-module--image--ohvFT";
export var content = "welcome-to-spaceport-section-module--content--+MmJj";
export var contentInner = "welcome-to-spaceport-section-module--content-inner--Vj84W";
export var titleWrapper = "welcome-to-spaceport-section-module--title-wrapper--Hwstn";
export var contentText = "welcome-to-spaceport-section-module--content-text--BJl-z";
export var mobileImageWrapper = "welcome-to-spaceport-section-module--mobile-image-wrapper---7CRN";
export var mobileImage = "welcome-to-spaceport-section-module--mobile-image--WtPc0";