import React from 'react';
import * as styles from './trip-of-a-lifetime-section.module.css';
import Container from '../Container';
import SectionTitle from '../SectionTitle';
import image from './images/image.png';
import mobileImage from './images/image-mob.jpg';
import TypographyBold from '../TypographyBold';


const TripOfALifetimeSection = () => {
  return (
    <section className={styles.container}>
      <Container size='lg'>
        <div className={styles.inner}>
          <div className={styles.imageContainer}>
            <div className={styles.imageWrapper}>
              <img src={image} className={styles.image} alt='Unity' />
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.contentInner}>
              <div className={styles.titleWrapper}>
                <SectionTitle>
                  A trip
                  <br/>
                  <TypographyBold> of a lifetime.</TypographyBold>
                </SectionTitle>
              </div>
              <div className={styles.mobileImageWrapper}>
                <img src={mobileImage} className={styles.mobileImage} alt='Unity' />
              </div>
              <div>
                <p className={styles.contentText}>
                  Our pre-flight Space Readiness Program consists of specific events, activities, and training designed to leave you physically, mentally and spiritually ready for space. Start to finish, you’ll be guided through every step to ensure your safety and comfort.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default TripOfALifetimeSection;
