import React from 'react';
import * as styles from './checking-shipshape-section.module.css';
import Container from '../Container';
import SectionTitle from '../SectionTitle';
import image from './images/image.jpg';
import mobileImage from './images/image-mob.jpg';
import TypographyBold from '../TypographyBold';


const CheckingShipshapeSection = () => {
  return (
    <section className={styles.container}>
      <Container size='lg'>
        <div className={styles.inner}>
          <div className={styles.contentWrapper}>
            <div className={styles.content}>
              <div className={styles.contentInner}>
                <div className={styles.titleWrapper}>
                  <SectionTitle>
                    Checking
                    <br/>
                    <TypographyBold> you’re shipshape.</TypographyBold>
                  </SectionTitle>
                </div>
                <div className={styles.mobileImageWrapper}>
                  <img src={mobileImage} className={styles.mobileImage} alt='Sir Richard Branson' />
                </div>
                <div>
                  <p className={styles.contentText}>
                    You’re never too old to go to space, but our rigorous health screenings will verify you’re fit to fly, whatever your age. That's why we have a detailed pre-flight medical questionnaire and a last-minute checkup for all astronauts to ensure you’re good to go.
                  </p>
                  <p className={styles.contentText}>
                    We also have a Flight Readiness Program, designed to help you prepare in every way possible for your trip to space. Our Virgin Galactic team will support you during your completion of optional zero-g and centrifuge training and acclimatization.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <div className={styles.imageWrapper}>
              <img src={image} className={styles.image} alt='Sir Richard Branson' />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CheckingShipshapeSection;
